import React, { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import FileDownload from 'js-file-download';
import Axios from 'axios';
import Alerts from '../../Alerts/Alerts';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

import './CargaPas.css';

function CargaPas() {
  // State variables
  const [file, setFile] = useState();
  const [alert, setAlert] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // Para redirigir a otra página
  const navigate = useNavigate();
  const location = useLocation();

  
  if(location.state) {
    const errorMsg = location.state.message;
    setAlert({type: 'error', message: errorMsg});
  }
  // Para poder obtener la info del servidor
  Axios.defaults.withCredentials = true;
  
  // Función al darle submit al formulaio del archivo PAS
  const handleFileUpload = async (event) => {
    event.preventDefault();

    // Si no hay archivo, evitamos que se haga el fetch
    if(!file) {
      setAlert({type: 'error', message: 'Favor de subir el archivo PAS .xlsx'});
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      // Activar el loading spinner
      setIsLoading(true);

      // Obtenemos la respuesta del servidor
      const response = await fetch('https://securitaspas.appdabi.com/api/v1/readPas', {
        method: 'POST',
        body: formData,
      });
      // Transformamos los datos en json
      const data = await response.json();
      
      if(data.status === 'error') {
        setAlert({type: data.status, message: data.error});
        // Desactivar el loading y activar el boton de submit
        setIsLoading(false);
      } else {
        // Si la respuesta fue exitosa
        setAlert({type: data.status, message: 'Archivo leído correctamente'});
        // Desactivar el loading y activar el boton de submit
        setIsLoading(false);
        // Enviamos la info al componente de previsualización de la info
        navigate('/confirmarPas', {state: {response: data}});
      }

    } catch (error) {}
  };

  // Guardamos un archivo cada que cambia el input
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  // Función para mostrar las alertas dinámicamente
  const showAlert = () => {
    if(alert.type) {
      return (
        <Alerts alert={alert}></Alerts>
      )
    }
  }

  const downloadFile = (event) => {
    event.preventDefault();

    Axios({
      url: 'https://securitaspas.appdabi.com/api/v1/downloadFile',
      method: 'GET',
      responseType: 'blob'
    }).then((res)=>{
      FileDownload(res.data, "PAS-DABI.xlsx");
    });
  }

  return (
    <div>
      {showAlert()}
      {/* Mostrar u ocultar el loading spinner */}
      {isLoading ? <LoadingSpinner /> : <div/>}

      <div className="read-pas">
        <div className="form-container">
            <form onSubmit={handleFileUpload} className="form-pas">
                <input type="file" id="file" name="file" className="upload-box" onChange={handleFileChange} />
                <button type="submit" className="btn btn-enviar" disabled={isLoading} >Subir PAS</button>
            </form>
        </div>

        <div className="download-container">
            <div className="download-container-text">
                <p>
                    Si no tienes el formato de PAS correcto, puedes descargarlo a continuación.
                    <br />
                    Asegúrate de llenar los datos de <b>Cliente, Proyecto y Pedido</b> en la hoja <b>"Revisiones"</b>
                </p>
            </div>        
            <div className="btn-descargar-container">
                <button className='btn btn-descargar' onClick={downloadFile}>Descargar PAS - Ejemplo</button>
            </div>
        </div>
      </div>

    </div>
  )
}

export default CargaPas